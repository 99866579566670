import React from "react";
import styles from "./styles.module.css";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <p>Alguns direitos reservados.</p>
    </div>
  );
};
